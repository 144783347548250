.primary-color {
  background-color: #026aff !important
}

.has-text-primary-color {
  color: #026aff !important
}

path {
  fill: #026aff;
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.white-background {
  background-color: white !important;
}

.blue-border {
  border-color:#026aff;
  border-style: solid;
}

.card radius-0  .card radius-0 -image,
.card radius-0  .card radius-0 -image .image img {
  height: 200px;
}

.image-slider {
  margin: 0 auto;
}

.image-size {
  width: 420px;
  height: 300px;
}

.logo-max-size {
  max-height: 3.25rem !important;
}

.radius-0 {
  border-radius: 0rem !important;
}

.text-underlined {
  text-decoration: underline;
  text-decoration-color: #026aff;
  text-decoration-thickness: 15px !important;
  text-decoration-style: solid;
  text-underline-offset: 20px;
}

.partners-image-size {
  width: 200px;
}

.mitacs-image-size {
  width: 300px;
}

.remove-border {
  border-bottom-style: none !important;
}

.is-rounded {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.card {
  height: 100%;
}

.image-size-tools {
  width: 600px;
  height: 500px;
}

select:not(.is-multiple):not(.is-loading)::after {
  border-color: #026aff;
}

.pagination-color {
  background-color: #026aff !important;
  border-color: #026aff !important;
}

#pre-line {
  white-space: pre-line;
}

.button-extra-large {
  font-size: 3em !important;
}

nav.navbar a.active {
  text-decoration: underline;
  text-decoration-color: white;
  text-decoration-thickness: 8px !important;
  text-decoration-style: solid;
  text-underline-offset: 10px;
}

